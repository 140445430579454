import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { facturationPageNavitems, billablePrestationTypes } from '../Constants'
import Alert from '@/components/shared/Alert.vue'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import Commons from '@/components/shared/Helpers/commons'
import { ValidationObserver } from 'vee-validate'
import { Subscription } from 'rxjs'
import { BillableSearchRequest, BillablePrestationResponse, InvoiceGenerationRequest, InvoiceItemGenerationRequest } from '@/models/invoice-models'
import { InvoiceService } from '@/services/invoice-service'
import { KeyValuePair } from '@/models/common-models'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class Recherche extends Vue {
  private patientService = PatientService.getInstance()
  private layoutService = LayoutService.getInstance()
  private invoiceService = InvoiceService.getInstance()

  public apiURL = process.env.VUE_APP_API_URL

  private navitems = facturationPageNavitems

  private billablePrestationTypes = billablePrestationTypes

  public globalCheckboxValue = false
  public globalCheckboxIndeterminate = false
  public searchModel: BillableSearchRequest = {}
  public assurances: any[] = []
  public rccNumbers: KeyValuePair<string, string>[] = []

  public subscription!: Subscription
  public headers: any[] = []
  public isSearching = false
  public assuranceLoading = true
  public rccLoading = false
  public items: BillablePrestationResponse[] = []
  public professionalDoctorsList: { id: number; fullname: string }[] = []

  public generating = false
  public selected: BillablePrestationResponse[] = []
  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public dateFacture = Commons.GetTodayFormatted()
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public async mounted () {
    this.layoutService.updateDrawerList(this.navitems)
    this.$set(this.searchModel, 'date', Commons.GetTodayFormatted())

    this.assuranceLoading = true
    this.rccLoading = true
    this.subscription = this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      } else {
        this.assuranceLoading = false
      }
    })
    this.invoiceService.getRCCNumbers().then((result: KeyValuePair<string, string>[]) => {
      this.rccNumbers = result
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.rccLoading = false
    })

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.headers = [
      {
        text: 'ID patient ',
        value: 'patientCode'
      },
      {
        text: 'Patient',
        value: 'patientFullName'
      },
      {
        text: 'Prestation',
        value: 'type',
        formatter: this.getTypeNameById,
        sort: function (a, b) {
          return that.getTypeNameById(a)!.localeCompare(that.getTypeNameById(b)!)
        }
      },
      {
        text: 'Date(s)',
        value: 'dates',
        formatter: this.formatDates,
        sortable: false
      },
      {
        text: 'Assurance',
        value: 'assuranceId',
        sort: function (a, b) {
          const aTransformed = that.getAssuranceName(a) ?? ''
          return aTransformed.localeCompare(that.getAssuranceName(b) ?? '')
        }
      }
    ]
  }

  public async search () {
    this.isSearching = true
    this.hideAlert()
    await this.invoiceService.search(this.searchModel).then((p: BillablePrestationResponse[]) => {
      this.items = p
      let idx = 0
      this.items.forEach(i => {
        i.id = ++idx
      })
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.isSearching = false
    })
  }

  public handleToggleAll (e) {
    if (e.value) {
      this.selected = this.items
    } else {
      this.selected = []
    }
  }

  public getTypeNameById (typeId: number) {
    return this.billablePrestationTypes.find(x => x.value === typeId)?.text
  }

  public getAssuranceName (id: number) {
    return this.assurances.find(a => a.id === id)?.assuranceName
  }

  public isMissingInsurance (item: BillablePrestationResponse) {
    return item.missingRequiredInsurance
  }

  public displayAssuranceName (item: BillablePrestationResponse) {
    if (this.isMissingInsurance(item)) {
      return 'Assurance Manquante'
    }
    return this.getAssuranceName(item.assuranceId)
  }

  public formatDates (dates: string[], separator = ', ') {
    const copy = [...dates]
    return copy.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map(d => Commons.TransformDateFormat(d)).join(separator)
  }

  public async generateInvoices () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      const request: InvoiceGenerationRequest = {
        invoiceDate: this.dateFacture,
        items: this.selected.map(s => {
          const req: InvoiceItemGenerationRequest = {
            type: s.type,
            patientId: s.patientId,
            appointmentIds: s.appointmentIds,
            relevantGuids: s.relevantGuids,
            assuranceId: s.assuranceId
          }
          return req
        })
      }
      this.generating = true
      this.invoiceService.generateInvoices(request).then(async () => {
        await this.search()
        this.selected = []
        this.updateAlertSuccessMessage()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      }).finally(() => {
        this.generating = false
      })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  private updateAlertSuccessMessage () {
    this.alertType = 'success'
    this.alertMessages = ["La génération des factures s'est déroulée avec succès"]
    this.showAlert = true
  }

  private updateAlertMessage (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  public resetSearch () {
    this.searchModel = {}
  }

  public get exportErrorHandler () {
    return (e) => {
      Commons.defaultVAuthHrefErrorHandler(this.alertMessages, e, (e) => this.updateAlertMessage({ errors: e, title: '' }))
    }
  }

  public get sortedBillablePrestationTypes () {
    return this.billablePrestationTypes.sort((a, b) => a.text.localeCompare(b.text))
  }
}
