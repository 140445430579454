import { PagedCollection } from '@/components/shared/Helpers/common-models'
import Commons from '@/components/shared/Helpers/commons'
import http from '@/http-client'
import { KeyValuePair } from '@/models/common-models'
import { BillableSearchRequest, BillablePrestationResponse, InvoiceGenerationRequest, InvoiceSearchRequest, InvoiceResponse, InvoiceEditRequest, InvoiceModel, NoteCreditCreationRequest, NoteCreditResponse, InvoiceItemModel } from '@/models/invoice-models'
import { InvoicePdfModel } from '@/models/pdf-model'
import { DataOptions } from 'vuetify/types'

export class InvoiceService {
  private static instance: InvoiceService

  public static getInstance (): InvoiceService {
    if (!InvoiceService.instance) {
      InvoiceService.instance = new InvoiceService()
    }
    return InvoiceService.instance
  }

  // miscellaneous
  public async getRCCNumbers () {
    const response = await http.get('/invoice/RCCNumber')
    return (response.data) as KeyValuePair<string, string>[]
  }

  // search billable
  public async search (request: BillableSearchRequest) {
    const response = await http.post('/invoice/search', request)
    return response.data as BillablePrestationResponse[]
  }

  // generate invoices
  public async generateInvoices (request: InvoiceGenerationRequest) {
    return await http.post('/invoice', request)
  }

  public async getInvoiceItems (invoiceId: number) {
    const response = await http.get(`/invoice/items/${invoiceId}`)
    return response.data as InvoiceItemModel[]
  }

  // search invoices
  public async searchInvoices (request: InvoiceSearchRequest, options: DataOptions) {
    const queryString: string[] = []
    Commons.PushPagingOptionsToQuerystring(queryString, options)
    if (options.sortBy.length === options.sortDesc.length) {
      options.sortBy.forEach((e, i) => {
        if (e === 'patientName') {
          const p1 = 'PatientLastName'
          const p2 = 'PatientFirstName'
          let s1 = `orderBy=${p1}`
          let s2 = `orderBy=${p2}`
          if (options?.sortDesc[i] === true) {
            s1 += ' desc'
            s2 += ' desc'
          }
          queryString.push(s1)
          queryString.push(s2)
        } else {
          let s = `orderBy=${e}`
          if (options?.sortDesc[i] === true) {
            s += ' desc'
          }
          queryString.push(s)
        }
      })
    }
    const qs = `?${queryString.join('&')}`
    const response = await http.post(`/invoice/searchInvoices${qs}`, request)
    return response.data as PagedCollection<InvoiceResponse>
  }

  public async getInvoice (id: number) {
    const response = await http.get(`/invoice/${id}`)
    return response.data as InvoiceModel
  }

  public async deleteInvoice (id: number) {
    if (!id) return 0
    const response = await http.delete(`/invoice/${id}`)
    return response.data as number
  }

  public async generateAndSendEInvoices (request: InvoiceSearchRequest) {
    const response = await http.post('/invoice/generateAndSendEInvoices', request)
    return response.data as number
  }

  public async editInvoice (request: InvoiceEditRequest) {
    const response = await http.put('/invoice', request)
    return response.data as number
  }

  public async downloadPdf (model: InvoicePdfModel) {
    const response = await http.post('/invoice/download', model, { responseType: 'blob' })
    return response
  }

  public async massDownload (models: InvoicePdfModel[]) {
    const response = await http.post('/invoice/massDownload', models, { responseType: 'blob' })
    return response
  }

  public async deleteNoteCredit (id: number) {
    if (!id) return 0
    const response = await http.delete(`/invoice/${id}/note-credit`)
    return response.data as number
  }

  public async createNoteCredit (id: number, model: NoteCreditCreationRequest) {
    if (!id) return 0
    const response = await http.post(`/invoice/${id}/note-credit`, model)
    return response.data as NoteCreditResponse
  }

  public async exportInvoicesToExcel (request: InvoiceSearchRequest, options: DataOptions) {
    const queryString: string[] = []
    if (options.sortBy.length === options.sortDesc.length) {
      options.sortBy.forEach((e, i) => {
        if (e === 'patientName') {
          const p1 = 'PatientLastName'
          const p2 = 'PatientFirstName'
          let s1 = `orderBy=${p1}`
          let s2 = `orderBy=${p2}`
          if (options?.sortDesc[i] === true) {
            s1 += ' desc'
            s2 += ' desc'
          }
          queryString.push(s1)
          queryString.push(s2)
        } else {
          let s = `orderBy=${e}`
          if (options?.sortDesc[i] === true) {
            s += ' desc'
          }
          queryString.push(s)
        }
      })
    }
    const qs = `?${queryString.join('&')}`
    const response = await http.post(`/invoice/exportToExcel${qs}`, request, { responseType: 'blob' })
    return response
  }

  public async getGrandTotalForInvoices (request: InvoiceSearchRequest) {
    const response = await http.post('/invoice/grandTotal', request)
    return response.data as number
  }
}
