export const facturationPageNavitems = [
  {
    icon: 'mdi-cash-plus',
    title: 'Prestations à facturer',
    to: '/facturation'
  },
  {
    icon: 'mdi-account-cash-outline',
    title: 'Factures',
    to: '/facturation/generated'
  }
]

export const InvoiceYearlySubscriptionType = 9

export const billablePrestationTypes = [
  { text: 'Diététique', value: 1 },
  { text: 'Diabétique', value: 2 },
  { text: 'Soins des pieds', value: 3 },
  { text: 'Prédiabète', value: 4 },
  { text: 'Pédiatrie', value: 5 },
  { text: 'Matériel (prescription)', value: 6 },
  { text: 'Matériel (commande libre)', value: 7 },
  { text: 'Rendez-vous manqué', value: 8 },
  { text: 'Cotisation membre', value: InvoiceYearlySubscriptionType }
]

export const invoiceModes = [
  { text: 'Électronique', value: 1 },
  { text: 'Papier', value: 2 }
]

export const CreatedStatus = 1
export const RefusedStatus = 7
export const ErrorStatus = 9

export const invoiceStatuses = [
  { text: 'Créé', value: CreatedStatus },
  { text: 'Facturé', value: 2 },
  { text: 'e - Prêt pour e-facturation', value: 3 },
  { text: 'e - Envoi en cours', value: 4 },
  { text: 'e - Envoyé (en traitement)', value: 5 },
  { text: 'e - Accepté par assureur', value: 6 },
  { text: 'e - Refusé par assureur', value: RefusedStatus },
  { text: 'e - En suspens', value: 8 },
  { text: 'e - Erreur', value: ErrorStatus },
  { text: 'Payé', value: 10 },
  { text: 'e - Payé', value: 11 },
  { text: 'Annulé', value: 12 },
  { text: 'Payé – Compensé par une note de crédit', value: 13 },
  { text: 'Contentieux – manque OM/OPAS', value: 14 },
  { text: 'Contentieux – cas spécial', value: 15 }
]

export enum InvoiceDocumentType {
  Invoice = 1,
  InvoiceReminder = 2,
  NoteCredit = 3,
}
